<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      v-model="sidebardetail"
      :no-header-close="true"
      bg-variant="light"
      right
      shadow
    >
      <template slots="title">
        <a-icon
          type="close-circle"
          class="text-secondary ml-3"
          @click="sidebardetail = false"
        />
        <span
          class="float-right mr-3"
          style="
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          "
          >Detail {{ datalengkap.pemohon.nama }}</span
        >
      </template>
      <!-- <template slots="header">
        <div class="row pl-1 pr-1 mb-2" style="margin-right: unset;">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"></div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <a-icon
              class="float-right"
              type="close"
              :style="'cursor: pointer;font-size: 20px;' + color"
              @mouseenter="color = 'color: #949494'"
              @mouseleave="color = 'color: #292929'"
              @click="sidebardetail = false"
            />
          </div>
        </div>
      </template> -->
      <div class="row pl-3" style="margin-right: unset;">
        <!-- <vue-custom-scrollbar
            class="scroll-area"
            :settings="settings"
            @ps-scroll-y="scrollHanle"
          >
          </vue-custom-scrollbar> -->
        <a-divider
          type="horizontal"
          style="margin-top: 10px; margin-bottom: 10px;"
        />
        <a-collapse accordion defaultActiveKey="7" style="width: 100%">
          <a-collapse-panel key="1" header="Data Pemohon">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Kode:</strong
              ><span> {{ datalengkap.pemohon.kode }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Nama:</strong
              ><span> {{ datalengkap.pemohon.nama }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Kelamin:</strong
              ><span> {{ datalengkap.pemohon.kelamin }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Gol. Darah:</strong
              ><span> {{ datalengkap.pemohon.golongandarah }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Tanggal Lahir:</strong
              ><span> {{ datalengkap.pemohon.tgllahir }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Tempat Lahir:</strong
              ><span> {{ datalengkap.pemohon.tempatlahir }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Status Perkawinan:</strong
              ><span> {{ datalengkap.pemohon.statusperkawinan }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>KTP:</strong
              ><span> {{ datalengkap.pemohon.ktp }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Pekerjaan:</strong
              ><span> {{ datalengkap.pemohon.pekerjaan }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Alamat:</strong
              ><span> {{ datalengkap.pemohon.alamat }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Kode Pos:</strong
              ><span> {{ datalengkap.pemohon.kodepos }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Telepon:</strong
              ><span> {{ datalengkap.pemohon.telepon }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Fax:</strong
              ><span> {{ datalengkap.pemohon.fax }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Alamat Kantor:</strong
              ><span> {{ datalengkap.pemohon.alamatkantor }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Telepon Kantor:</strong
              ><span> {{ datalengkap.pemohon.teleponkantor }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Fax Kantor:</strong
              ><span> {{ datalengkap.pemohon.faxkantor }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Kode Pos Kantor:</strong
              ><span> {{ datalengkap.pemohon.kodeposkantor }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Path:</strong
              ><span> {{ datalengkap.pemohon.path }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>ao:</strong
              ><span> {{ datalengkap.pemohon.ao }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Plafond:</strong
              ><span> {{ datalengkap.pemohon.plafond }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Lama:</strong
              ><span> {{ datalengkap.pemohon.lama }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Jaminan:</strong
              ><span> {{ datalengkap.pemohon.jaminan }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Tanggal Register:</strong
              ><span> {{ datalengkap.pemohon.tglregister }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Status Pengajuan:</strong
              ><span> {{ datalengkap.pemohon.statuspengajuan }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Sifat Kredit:</strong
              ><span> {{ datalengkap.pemohon.sifatkredit }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Jenis Penggunaan:</strong
              ><span> {{ datalengkap.pemohon.jenispenggunaan }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Golongan Debitur:</strong
              ><span> {{ datalengkap.pemohon.golongandebitur }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Golongan Kredit:</strong
              ><span> {{ datalengkap.pemohon.golongankredit }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Sektor Ekonomi:</strong
              ><span> {{ datalengkap.pemohon.sektorekonomi }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Wilayah:</strong
              ><span> {{ datalengkap.pemohon.wilayah }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Golongan Penjamin:</strong
              ><span> {{ datalengkap.pemohon.golonganpenjamin }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Rekening Tabungan:</strong
              ><span> {{ datalengkap.pemohon.rekeningtabungan }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Cara Perhitungan:</strong
              ><span> {{ datalengkap.pemohon.caraperhitungan }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Linkage:</strong
              ><span> {{ datalengkap.pemohon.linkage }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Notariil:</strong
              ><span> {{ datalengkap.pemohon.notariil }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Suku Bunga:</strong
              ><span> {{ datalengkap.pemohon.sukubunga }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Pokok:</strong
              ><span> {{ datalengkap.pemohon.pokok }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Bunga:</strong
              ><span> {{ datalengkap.pemohon.bunga }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Nilai Jaminan:</strong
              ><span> {{ datalengkap.pemohon.nilaijaminan }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Alamat Usaha:</strong
              ><span> {{ datalengkap.pemohon.alamatusaha }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Nama Ibu Kandung:</strong
              ><span> {{ datalengkap.pemohon.namaibukandung }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Keterangan:</strong
              ><span> {{ datalengkap.pemohon.keterangan }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
          </a-collapse-panel>
          <a-collapse-panel key="2" header="Data Tanah dan Bangunan">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Jenis Surat Tanah:</strong
              ><span> {{ datalengkap.jenissurattanah }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Terdaftar Atas Nama:</strong
              ><span> {{ datalengkap.terdaftaratasnama }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Nama Pemilik:</strong
              ><span> {{ datalengkap.namapemilik }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Lokasi:</strong
              ><span> {{ datalengkap.lokasi }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Ditempat Oleh:</strong
              ><span> {{ datalengkap.ditempatioleh }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Dasar Menempati:</strong
              ><span> {{ datalengkap.dasarmenempati }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
          </a-collapse-panel>
          <a-collapse-panel key="3" header="Kondisi Tanah dan Bangunan">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Topografi:</strong
              ><span> {{ datalengkap.topografi }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Bentuk Tanah:</strong
              ><span> {{ datalengkap.bentuktanah }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Batas Utara:</strong
              ><span> {{ datalengkap.batasutara }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Batas Timur:</strong
              ><span> {{ datalengkap.batastimur }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Batas Selatan:</strong
              ><span> {{ datalengkap.batasselatan }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Batas Barat:</strong
              ><span> {{ datalengkap.batasbarat }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Luas Tanah:</strong
              ><span> {{ datalengkap.luastanah }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Luas Bangunan:</strong
              ><span> {{ datalengkap.luasbangunan }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Peruntukan:</strong
              ><span> {{ datalengkap.peruntukan }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Kemungkinan Banjir:</strong
              ><span> {{ datalengkap.kemungkinanbanjir }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Konstruksi:</strong
              ><span> {{ datalengkap.konstruksi }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Dinding:</strong
              ><span> {{ datalengkap.dinding }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Lantai:</strong
              ><span> {{ datalengkap.lantai }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Atap:</strong
              ><span> {{ datalengkap.atap }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Langit Langit:</strong
              ><span> {{ datalengkap.langitlangit }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Kusen:</strong
              ><span> {{ datalengkap.kusen }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Pintu:</strong
              ><span> {{ datalengkap.pintu }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Jendela:</strong
              ><span> {{ datalengkap.jendela }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Pagar:</strong
              ><span> {{ datalengkap.pagar }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Listrik:</strong
              ><span> {{ datalengkap.listrik }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Air:</strong
              ><span> {{ datalengkap.air }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Telepon:</strong
              ><span> {{ datalengkap.telepon }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
          </a-collapse-panel>
          <a-collapse-panel key="4" header="Data Pendukung Lainnya">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Sarana Jalan:</strong
              ><span> {{ datalengkap.saranajalan }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Lebar Jalan:</strong
              ><span> {{ datalengkap.lebarjalan }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Penentuan Value:</strong
              ><span> {{ datalengkap.penentuanvalue }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Keterangan:</strong
              ><span> {{ datalengkap.keterangan }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
          </a-collapse-panel>
          <a-collapse-panel key="5" header="Metode Penilaian">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Taksiran Nilai Bangunan:</strong><span> {{ datalengkap.taksirannilaibangunan }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Penyusutan Nilai Jaminan:</strong><span> {{ datalengkap.penyusutannilaijaminan }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Usia Bangunan:</strong><span> {{ datalengkap.usiabangunan }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
          </a-collapse-panel>
          <a-collapse-panel key="7" header="Analisa">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Analisa:</strong><span> {{ datalengkap.analisa }}.</span>
            </div>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </b-sidebar>
    <!-- <htmlpdf
      :columns="columns"
      :datatable="this.datalengkap.detail"
      :previewModal="false"
      :filename="this.datalengkap.faktur"
      ref="htmlpdf"
    /> -->
  </div>
</template>

<script>
// import vueCustomScrollbar from 'vue-custom-scrollbar'
import * as lou from '@/services/data/lou'
// import htmlpdf from '@/components/lou/pdfprint.vue'
export default {
  components: {
    // vueCustomScrollbar,
    // htmlpdf,
  },
  name: 'right-bar',
  props: ['title'],
  data() {
    return {
      activeKey: ['1'],
      datalengkap: {
        pemohon: {
          nama: '',
        },
      },
      color: 'color: #292929;',
      color1: 'color: #292929;',
      settings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: false,
      },
      sidebardetail: false,
    }
  },
  computed: {},
  methods: {
    scrollHanle(evt) {
      // console.log(evt)
    },
    open(record) {
      // console.log('record.detail.length', record.detail.length)
      this.sidebardetail = this.datalengkap !== record ? true : !this.sidebardetail
      this.datalengkap = record
    },
    // printpdf() {
    //   this.$refs.htmlpdf.generateReport(
    //     this.datalengkap.detail,
    //     this.datalengkap.pelanggan,
    //   )
    // },
    close() {
      this.sidebardetail = false
    },
    numberFormat(value) {
      return lou.curency(value)
    },
  },
}
</script>

<style>
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 320px;
}
</style>
